export const scoreListData = [
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
  {
    URL: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Quantity: "2",
    Threads: "1",
    Status: "Added",
    Delete: "Delete",
  },
];
export const filterData = [
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
  {
    name: " Filter 1",
    Price: "20 - 2000",
    Sections:
      "{[“iddgdfgfg   +6+9s5sf222551dfdfsf51vfdfs5f1ff2b1d56f1sd5g1df5g1df5f1sa5d1}] ",
  },
];
export const LaunchData = [
  {
    Sent: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Address: "faiji786@gmail.com",
    Type: "Add to Cart",
    Launch: "Add to Cart",
  },
  {
    Sent: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Address: "faiji786@gmail.com",
    Type: "Add to Cart",
    Launch: "Add to Cart",
  },
  {
    Sent: "No Queue for http://www.ticketbot.com/dsfsdgfdgfdgbdfbfbfgbfds35fd21gsef1se1f21dfg1es2g1g",
    Address: "faiji786@gmail.com",
    Type: "Add to Cart",
    Launch: "Add to Cart",
  },
];
export const filterSearchData = [
  {
    Sections: "Section1",
    Rows: "12, 13",
  },
  {
    Sections: "Section1",
    Rows: "12, 13",
  },
];
export const TicketTableData = [
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Paused",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
  {
    Section: "118",
    Row: "13",
    Seats: "11,12",
    Qty: "02",
    Price: "CA $600.00",
    Ticket: "CA $300.00",
    remaining: "07:18",
    Status: "Ticket(s) Ready",
    Proxy: "172.28.30.200",
    Event: "Travis scot tomorr...",
    Description: "Travis scot tomorr...",
    Running: "Running",
  },
];

export const accountData = [
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
  {
    email: "faiji786@gmail.com",
    Threads: "id1",
    Delete: "Delete",
  },
];

export const CardTableData = [
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
  {
    Digits: "5431",
  },
];
