export default {
  h1: {
    fontWeight: 500,
    fontSize: 55,
    lineHeight: "66px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "28px !important",
      lineHeight: "42px",
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: 35,
    lineHeight: "50px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "18px !important",
      lineHeight: "30px !important",
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    lineHeight: "40px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "15px !important",
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "30px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "13px !important",
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "30px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "16px !important",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "30px",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "12px !important",
    },
  },

  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body2: {
    fontSize: 14,
    lineHeight: "25px",
    fontWeight: "300",
    fontFamily: "'Sora', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "12px !important",
      lineHeight: "25px",
    },
  },
  body1: {
    fontSize: 12,
    lineHeight: "20px",
    fontWeight: "300",
    fontFamily: "'Sora', sans-serif",
  },
  overline: {
    fontWeight: 500,
  },
};
