import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FilterTable from "./FilterTable";

const useStyles = makeStyles(() => ({
  modaltext: {
    "& h3": {
      color: "#fff",
    },
    "& .MuiCheckbox-root": {
      padding: "0px",
      color: "#6D61FF",
    },

    "& .MuiDialogTitle-root": {
      padding: "9px 17px",
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .manageSection": {
      padding: "16px",
      border: "1px solid rgba(255, 255, 255, 0.08)",
      borderRadius: "10px",
      marginTop: "8px",
    },
    "& .buttonManage": {
      color: "",
    },
  },
}));
export default function CommonConfirmationModal({
  open,
  close,
  title,
  description,
  btnName,
  onClick,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => close(false)}
      maxWidth="xs"
      fullWidth
      className={classes.modaltext}
    >
      <DialogTitle>
        <Typography variant="h3" align="center">
          {title}
        </Typography>

        <Box
          onClick={() => {
            close(false);
          }}
          color="primary"
          style={{
            height: "0px",
            position: "absolute",
            right: "10px",
            top: "17px",
            cursor: "pointer",
          }}
        >
          <ClearIcon style={{ color: "#fff" }} />
        </Box>
      </DialogTitle>
      <Box>
        <Divider />
      </Box>
      <DialogContent>
        <Box>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {" "}
            {description}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              close(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            style={{ marginLeft: "5px" }}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
