import React, { useState } from "react";
import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import { filterSearchData } from "src/data/index";
const useStyles = makeStyles((theme) => ({
  tableBox: {
    minWidth: "800px",
  },
  tableContainer: {
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    position: "relative",
    zIndex: "9999",
    "& .buttonStyleMain": {
      border: "1px solid rgba(134, 14, 14, 1)",
      color: "#fff",
      borderRadius: "8px",
    },
  },
  rowOdd: {
    background: "rgba(255, 255, 255, 0.02)",
  },
  rowEven: {
    background: "rgba(255, 255, 255, 0)",
  },
}));
function FilterTable() {
  const classes = useStyles();

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.tableBox}>
          <TableHead>
            <TableRow>
              <TableCell align="left">#</TableCell>
              <TableCell align="left">Sections</TableCell>
              <TableCell align="left">Rows</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterSearchData.map((data, i) => (
              <TableRow
                key={i}
                className={i % 2 === 0 ? classes.rowOdd : classes.rowEven}
              >
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{data.Sections || "N/A"}</TableCell>
                <TableCell align="left">{data.Rows || "N/A"}</TableCell>

                <TableCell align="right">
                  <Button className="buttonStyleMain">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default FilterTable;
