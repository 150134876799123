import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  palette: {
    primary: {
      main: "rgba(255, 255, 255, 0.87)",
    },
    secondary: {
      main: "rgba(255, 255, 255, 0.6)",
    },
    background: {
      main: "#080031",
    },
    text: {
      primary: "#080031",
      secondary: "#B8C3FF",
    },
  },
  direction: "ltr",
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        color: "rgba(255, 255, 255, 0.6)",
      },
    },

    MuiDialog: {
      paper: {
        borderRadius: "10px",
        background: "#19191D",
        filter: "none",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          left: "0",
          right: "0",
          bottom: "0",
          content: '"\\00a0"',
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "2px solid rgb(94 207 240)",
          pointerEvents: "none",
        },
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: "10px",
        "&:-webkit-autofill": {
          caretColor: "transparent",
          borderRadius: "inherit",
          WebkitBoxShadow: "0 0 0 100px #ffffff inset",
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "rgba(0, 0, 0, 0.60)",
        },
        "&:-internal-autofill-selected": {
          color: "rgba(0, 0, 0, 0.60)",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: "0px",
        background: "#19191D",
        color: "rgba(255, 255, 255, 0.60)",
        "&:-webkit-autofill": {
          caretColor: "transparent",
          borderRadius: "inherit",
          WebkitBoxShadow: "0 0 0 100px #19191D inset",
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "rgba(255, 255, 255, 0.60)",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
      root: {
        borderRadius: "10px",
      },
      notchedOutline: {
        borderColor: "rgba(255, 255, 255, 0.12)",
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        padding: "20px",
        borderRadius: "10px",
        background: "rgba(255, 255, 255, 0.02)",
        boxShadow: "none",
      },
      elevation2: {
        padding: "20px",
        borderRadius: "10px",
        background: "#19191D",
        boxShadow: "none",
        backdropFilter: "blur(4px)",
      },
      root: {
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        background: "#fff",
        boxShadow: "0px 0px 32px rgba(129, 23, 147, 0.03)",
        color: "rgba(255, 255, 255, 0.87)",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6D61FF",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "rgba(255, 255, 255, 0.87)",
      },
      iconButton: {
        backgroundColor: "#E64542",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#6D61FF",
      },
      day: {
        color: "rgba(255, 255, 255, 0.87)",
      },
    },
    MuiSelect: {
      iconOutlined: {
        background: "none",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "#fff",
        // backgroundColor: "#212226",
      },
      colorDefault: {
        backgroundColor: "none",
      },
    },

    MuiAccordionSummary: {
      root: {
        // background: "#171616",
        background: "rgba(255, 255, 255, 0.05)",
        borderRadius: "15px 15px 0px 0px",
      },
    },
    MuiAccordionDetails: {
      root: {
        background: "rgba(255, 255, 255, 0.025)",
        borderRadius: "0px 0px 15px 15px",
      },
    },
    MuiSelect: {
      icon: {
        top: "calc(50% - 20px)",
        color: "#fff",
        right: "0",
        position: "absolute",
        pointerEvents: "none",
        background: "rgba(255, 255, 255, 0.025)",
        borderRadius: "50%",
        padding: "8px",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
      containedSizeSmall: {
        height: "40px",
        padding: "4px 12px",
        fontSize: "14px",
      },
      containedSecondary: {
        color: "rgba(38, 36, 36, 0.87)",
        padding: "10px 35px",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        borderRadius: "10px",
        background: "#fff",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.16)",
        backgroundColor: "#fff",
        height: "44px",
        whiteSpace: "pre",
        "&:hover": {
          color: "#ffffff",
          background:
            "var(--Linear, linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%))",
          backgroundColor: "#fff",
        },
        "@media(max-width:767px)": {
          fontSize: "10px !important",
        },
      },

      containedPrimary: {
        color: "#fff",
        padding: "10px 35px",
        fontSize: "14px",
        borderRadius: "10px",
        border: "none",
        background: "#6D61FF",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.16)",
        fontWeight: "400",
        lineHeight: "21px",
        height: "44px",
        whiteSpace: "pre",
        backgroundColor: "#6D61FF",
        "&:hover": {
          background: "#fff !important",
          backgroundColor: "#fff",
          color: "rgba(38, 36, 36, 0.87)",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.16)",
        },
        "@media(max-width:767px)": {
          fontSize: "12px !important",
        },
      },
      outlinedPrimary: {
        color: "#6D61FF",
        padding: "10px 35px",
        fontSize: "14px",
        borderRadius: "10px",
        border: "none",
        fontWeight: "400",
        lineHeight: "21px",
        height: "44px",
        whiteSpace: "pre",
        border: "1px solid #6D61FF",
        // "&:hover": {
        //   // background: "#fff !important",
        //   // backgroundColor: "#fff",
        //   color: "rgba(38, 36, 36, 0.87)",
        //   boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.16)",
        // },
        "@media(max-width:767px)": {
          fontSize: "10px !important",
        },
      },
    },

    MuiTableHead: {
      root: {
        background: "rgba(255, 255, 255, 0.05)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.05)",

        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiTableBody: {
      root: {
        background: "rgba(255, 255, 255, 0.04)",
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          outline: "0",
          background: "rgba(255, 255, 255, 0.02) !important",
        },
      },
    },
    MuiTableRow: {
      root: {
        // borderBottom: "1px solid #636262",
        // "&:hover": {
        //   backgroundColor: "#ffffff14",
        // },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiTableCell: {
      head: {
        padding: "18px 14px",
        fontWeight: "400",
        color: "#fff",
        whiteSpace: "pre",
      },
      body: {
        color: "#DEDEDE",
        whiteSpace: "pre",
      },
      root: {
        borderBottom: "none",
        padding: "11px 14px",
        textAlign: "center",
        fontSize: "12px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "20px 18px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "20px 20px 0px",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",

    typography: {},
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",

    typography: {},

    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
